@import "../variables";

.actualites {
  display: block;
  width: 100%;
}
.actualite {
  position: relative;  
  width: $centercol;
  margin:2em auto;
  border-bottom: 1px solid $brandcolor1;
 padding-bottom: 2em;
}
.actualite p{
text-align: justify;
}
.actualite > h1{
  position: relative;
  color: $brandcolor1;
  font-size: calc(1vw + 0.3rem);
  margin-bottom: 1em;
}

@media (min-width: 300px) and (max-width: 768px) {
  .actualite {
      width: 100%;
  }
  .actualite > h1{    
    font-size: 1.5em;
  }
}
.actualite figure {
  position: relative;
  padding-bottom: 1em;
}

.actualite img {
  width: auto;
  height: 150px;
  object-fit: cover;
  transition: all .3s;
}
.actualite img:hover {
  opacity: .2;
}
